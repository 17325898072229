import { Input, Space, Button, Form, message, Row, Col, Divider, Typography, Popconfirm, Select, Upload, InputNumber } from "antd";
import React, { useState } from "react";
import { useGetQualityControlDetailQuery, useUpdateQualityControlMutation, useGetMainprojectLevelTypeQuery, useUpdateQualityControlAttachedMutation, BASE_URL } from "../../features/api/userAPI";
import { useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import ContentWrap from "../../components/ContentHead";
import GroupList from "./GroupList";
const { Title } = Typography
const QualityControlAdd = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { data, isLoading } = useGetQualityControlDetailQuery({ id })

  const [form] = useForm()
  const [updateQualityControl, { isLoading: updateIsLoading }] = useUpdateQualityControlMutation()
  const [updateQualityControlAttached] = useUpdateQualityControlAttachedMutation()

  const { isLoading: getMainProjectLoading } = useGetMainprojectLevelTypeQuery()
  const [fileList, setFileList] = useState([])
  const [fileList1, setFileList1] = useState([])
  const [requiredRule, setRequiredRule] = useState({ required: true, message: "这个字段是必须的" })
  const [requiredFileRule, setRequiredFileRule] = useState({ required: true, message: "这个字段是必须的" })
  useEffect(() => {
    const attached = data?.attached
    if (attached) {
      const fileList = [{ uid: -1, name: data.attached_name, status: 'done', url: attached }]
      setFileList(fileList);
    }
    const attached1 = data?.attached1
    if (attached1) {
      const fileList1 = [{ uid: -1, name: data.attached_name1, status: 'done', url: attached1 }]
      setFileList1(fileList1);
    }
  }, [data]);
  const breadItmes = [
    { title: "评优评先" },
    { title: "质量管理", path: "/qualitycontrol" },
    { title: "修改" }
  ]

  const navigate_list = () => {
    if (form.getFieldValue("status") !== 1) {
      setRequiredRule({ ...requiredRule, required: true })
      if ((fileList && fileList.length > 0) && (fileList1 && fileList1.length > 0)) {
        setRequiredFileRule({ ...requiredFileRule, required: false })
      } else {
        setRequiredFileRule({ ...requiredFileRule, required: true })
      }
    } else {
      navigate("/qualitycontrol", { replace: true })
    }
  }
  const onFinish = (values) => {

    const payload = Object.fromEntries(
      Object.entries(values).filter(([_, value]) => value !== null && value !== undefined && value !== "")
    );
    console.log("@53", payload);
    updateQualityControl({ payload, id }).then((result) => {
      const _id = result?.data?.id
      if (_id) {
        message.success("保存成功")
        navigate_list()
      } else {
        message.error("登录超时，请重新登录")
      }
    })
  };
  const handleUploadChange = (info) => {
    setFileList([...info.fileList]);
  }
  const handleUploadChange1 = (info) => {
    setFileList1([...info.fileList]);
  }
  const addOrClearAttached = ({ formData, type }) => {
    updateQualityControlAttached({ payload: formData, id, type }).then((result) => {
      const callBackFun = type === 1 ? setFileList1 : setFileList
      const rst = result?.data?.result
      const errorMessage = result?.data?.message
      const url = `${BASE_URL}${result?.data?.url}`
      const filename = result?.data?.filename
      if (rst !== "ok") {
        message.error(`保存失败: ${errorMessage}`)
      } else {
        if (result?.data?.url) {
          callBackFun([{
            uid: url,
            url: url,
            name: filename,
            status: 'done',
          }])
        } else {
          callBackFun(null)
        }
      }
    })
  }

  const title = "质量管理小组活动申请表"
  const memberID = data?.member
  const status = data?.status
  const year = data?.year
  const lastYear = year - 1
  return (
    <ContentWrap breadItems={breadItmes}>
      {isLoading || getMainProjectLoading ?
        <LoadingOutlined /> :
        <Form
          form={form}
          className="mt-10 ml-20"
          onFinish={onFinish}
          scrollToFirstError
          style={{ maxWidth: 800 }}
        >
          <Form.Item hidden name={"year"} initialValue={year} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"id"} initialValue={data?.id} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"member"} initialValue={memberID} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Form.Item hidden name={"status"} initialValue={status} noStyle>
            <Input type="text1" className="ant-form-text" />
          </Form.Item>
          <Title className="text-center" level={3}>{title}</Title>
          <p className="mt-8 text-sm text-[#757575]">
          提醒：本活动申报年度为（{lastYear}年11月~{year}年11月）
          </p>
          <p className="text-sm text-[#757575]">
          本活动遵循《T/CAQ10201-2020质量活动管理小组准则》。
          </p>
          <Divider></Divider>
          <Row gutter={[16, 0]}>
            <Col span={10}>
              <Form.Item
                name={"name"}
                label="单位名称"
                initialValue={data?.name}
                rules={[requiredRule]}
              >
                <Input readOnly type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name={"tel"}
                label="电话"
                initialValue={data?.tel}
                rules={[requiredRule]}
              >
              <InputNumber precision={0} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"email"}
                label="电子邮箱"
                initialValue={data?.email}
                rules={[requiredRule]}
              >
              <InputNumber precision={0} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                initialValue={data?.company_address}
                name={"company_address"}
                label="单位地址"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"zipcode"}
                label="邮编"
                initialValue={data?.zipcode}
                rules={[requiredRule]}
              >
              <InputNumber precision={0} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                initialValue={data?.subject_name}
                name={"subject_name"}
                label="课题名称"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.economic_benefits}
                name={"economic_benefits"}
                label="经济效益（万）"
                rules={[requiredRule]}
              >
                <InputNumber precision={4} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item
                initialValue={data?.group_name}
                name={"group_name"}
                label="小组名称"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"group_type"}
                label="小组类型"
                initialValue={data?.group_type}
                rules={[requiredRule]}
              >
                <Select
                  options={[
                    { label: "创新型", value:1 },
                    { label: "问题解决型", value:2 },
                  ]}
                  placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"group_count"}
                label="小组人数"
                initialValue={data?.group_count}
                rules={[requiredRule]}
              >
              <InputNumber precision={0} className="w-full" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                initialValue={data?.training_name}
                name={"training_name"}
                label="参加何种培训"
                rules={[requiredRule]}
              >
                <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"publish_or_exchange"}
                label="发表/交流"
                initialValue={data?.publish_or_exchange}
              >
             <Select
                  options={[
                    { label: "请选择", value:"" },
                    { label: "发表", value:"发表" },
                    { label: "交流", value:"交流" },
                  ]}
                  placeholder="请选择" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"registe_group_number"}
                label="质量管理小组注册号"
                initialValue={data?.registe_group_number}
                rules={[requiredRule]}
              >
             <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"registe_subject_number"}
                label="质量管理课题注册号"
                initialValue={data?.registe_subject_number}
                rules={[requiredRule]}
              >
             <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={"published_by"}
                label="发表人"
                initialValue={data?.published_by}
                rules={[requiredRule]}
              >
              <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"group_contract"}
                label="小组联系人"
                initialValue={data?.group_contract}
                rules={[requiredRule]}
              >
              <Input type="text1" className="ant-form-text" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={"contract_phone"}
                label="手机号码"
                initialValue={data?.contract_phone}
                rules={[requiredRule]}
              >
              <InputNumber precision={0} className="w-full" />
              </Form.Item>
            </Col>
          </Row>
          <Divider orientation="left"></Divider>
            <Form.Item
                name={"group_list"}
                label="小组成员名单"
                extra="每个空格填写1个人，最多填10人"
                initialValue={data?.group_list}
                rules={[requiredRule]}>
              <GroupList />
            </Form.Item>
          <Divider orientation="left"></Divider>
          <Form.Item
            rules={[requiredRule]}
            initialValue={data?.enterprise_opinion}
            labelCol={{
              span: 24,
            }}
            hidden
            name={"enterprise_opinion"}
            label={<b>企业意见</b>}>
            <TextArea
              autoSize={{ minRows: 4 }} ></TextArea>
          </Form.Item>
          <Form.Item
            name="tempUploadFile1"
            label="成果文件"
            extra=""
            rules={[requiredFileRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              // console.log("@664", rst);
              return rst
            }}
          >
            <Upload
              accept="application/msword"
              customRequest={({ file }) => {
                const formData = new FormData()
                const type = 1
                formData.append("attached1", file)
                formData.append("year", year)
                addOrClearAttached({ formData, type })
              }}
              onRemove={() => {
                const formData = new FormData()
                const type = 1
                formData.append("attached1", '')
                formData.append("year", year)
                addOrClearAttached({ formData, type })
              }}
              fileList={fileList1}
              onChange={handleUploadChange1}
              beforeUpload={(file) => {
                const isPDF = file?.type === "application/msword";
                if (!isPDF) {
                  message.error(`${file.name} 不是word文件`)
                  return Upload.LIST_IGNORE
                }
              }}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            name="tempUploadFile"
            label="现场评审表"
            extra=""
            rules={[requiredFileRule]}
            valuePropName="fileList"
            getValueProps={(value) => {
              return value
            }}
            getValueFromEvent={(value) => {

              const fileList = value?.fileList
              let rst = undefined
              if (fileList && fileList.length > 0) {
                rst = fileList
              }
              // console.log("@664", rst);
              return rst
            }}
          >
            <Upload
              accept="application/pdf"
              customRequest={({ file }) => {
                const formData = new FormData()
                const type = 0;
                formData.append("attached", file)
                formData.append("year", year)
                addOrClearAttached({ formData, type })
              }}
              onRemove={() => {
                const formData = new FormData()
                const type = 0;
                formData.append("attached", '')
                formData.append("year", year)
                addOrClearAttached({ formData, type })
              }}
              fileList={fileList}
              onChange={handleUploadChange}
              beforeUpload={(file) => {
                const isPDF = file?.type === "application/pdf";
                if (!isPDF) {
                  message.error(`${file.name} 不是PDF文件`)
                  return Upload.LIST_IGNORE
                }
              }}
              maxCount={1}
              listType="picture">
              <Button icon={<UploadOutlined />}>上传材料</Button>
            </Upload>
          </Form.Item>
          {data.status === 0 &&
            <Form.Item
              wrapperCol={{
                span: 24,
              }}
              className="text-center"
            >
              <Space size={"large"}>
                <Button size="large" onClick={() => {
                  setRequiredRule({ ...requiredRule, required: false })
                  setRequiredFileRule({ ...requiredFileRule, required: false })
                  form.setFieldValue("status", 0)
                  setTimeout(() => {
                    form.submit()
                  }, 100)
                  // form.submit()
                }} loading={updateIsLoading} >
                  保存
                </Button>
                <Popconfirm
                  title="提醒"
                  description="提交后表单无法修改，您确认要提交吗？"
                  onConfirm={() => {
                    setRequiredRule({ ...requiredRule, required: true })
                    if (fileList && fileList.length > 0) {
                      setRequiredFileRule({ ...requiredFileRule, required: false })
                    }
                    form.setFieldValue("status", 1)
                    setTimeout(() => {
                      form.submit()
                    }, 100)
                    // form.submit()
                  }}
                  okText="确定"
                  cancelText="取消"
                >

                  <Button size="large" loading={updateIsLoading} type="primary">提交</Button>
                </Popconfirm>
              </Space>
            </Form.Item>
          }
        </Form >
      }
    </ContentWrap>
  )
}

export default QualityControlAdd
